import { types } from "@redux/types/types";

const initialState = {
    registros: [],
    endPoint: null,
    count: 0,
    page: 1,
    limit: 25,
    sortField: null,
    sort: null,
};

const registrosReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.changeList:
            return {
                ...state,
                [action.payload.name]: action.payload.valor,
            };
        case types.load:
            return {
                ...state,
                endPoint: action.payload.endPoint,
                count: action.payload.count,
                registros: action.payload.data,
            };
        case types.delete:
            return {
                ...state,
                registros: state.registros.filter((reg) => reg.id !== action.payload),
            };
        case types.deleteRepair:
            state.registros[action.payload.index].repairs = state.registros[action.payload.index].repairs.filter(
                (reg) => reg.id !== action.payload.id,
            );

            return {
                ...state,
                registros: [...state.registros],
            };
        case types.cleaningAll:
            return {
                ...state,
                endPoint: null,
                registros: [],
            };
        case types.change:
            const { index, name, valor } = action.payload;

            const newReg = {
                ...state.registros[index],
                [name]: valor,
            };

            return {
                ...state,
                registros: state.registros.map((reg, i) => (i === index ? newReg : reg)),
            };
        case types.changeRegister:
            const { val, nam, id } = action.payload;
            const newTab = {
                ...state.registros.find((element) => {
                    if (element.id === id) {
                        return element;
                    }
                }),
                [nam]: val,
            };

            return {
                ...state,
                registros: state?.registros.map((element) => (element.id === id ? newTab : element)),
            };

        default:
            return state;
    }
};

export default registrosReducer;
